export const environment = {
  production: true,
  sign_in: 'https://learning.cirrusapproach.com/sign-in',
  sign_out: 'https://learning.cirrusapproach.com/sign-out',
  profile: 'https://cirrusaircraft.com/account/profile/',
  api_domain: 'https://learning.cirrusapproach.com',
  media_server_domain: 'https://learning.cirrusapproach.com',
  sentry_dsn: 'https://249766179e224c0da7e544f50e18d0ef:cc784c1aef224b71a5b7be6b8fc1ba80@o510693.ingest.sentry.io/5606725',
  sentry_environment: 'production',
  sourceVersion: '805204d2795cf03a7a55eecba2c9f38463859ba3',
  defaultThumbnailCourse:
    'https://cirrusapproachherokuprod.blob.core.windows.net/cirruslmsherokudevcontainer/content-items/images/default-course-thumbnail.jpg',
  stsServer: 'https://account.cirrusaircraft.com',
  redirectUrl: 'https://learning.cirrusapproach.com/authenticated',
  postLogoutRedirectUri: 'https://cirrusapproach.local',
  clientId: '3MVG9QDx8IX8nP5TdBrsPI4zwvv2Kbv0uHLwY8qrZ9mvvQtZK_BeXzCr_53AEYH.lkJknoMAQGUosKvraCTow',
  scope: 'openid profile email',
  responseType: 'id_token token',
  logoutUrl: 'https://cirrusaircraft.com/?logout-current-user',
  // Remove me once force completion is live...
  force_completion: 'false',
  fullstoryOrgId: 'o-1NX9CW-na1',
  analyticsEnabled: true,
};
